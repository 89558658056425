import React, { useState } from 'react';
import AdminLogin from '../components/AdminLogin';
import SupplierManager from '../components/SupplierManager';
import UserManager from '../components/UserManager';
import { 
  Container, 
  Typography, 
  Box, 
  Tabs, 
  Tab,
  Paper 
} from '@mui/material';

const AdminPage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Container>
      {!isAuthenticated ? (
        <AdminLogin onLogin={handleLogin} />
      ) : (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Administration
          </Typography>
          
          <Paper sx={{ mb: 3 }}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Gestion des Utilisateurs" />
              <Tab label="Gestion des Fournisseurs" />
            </Tabs>
          </Paper>

          {activeTab === 0 && (
            <Box sx={{ py: 2 }}>
              <UserManager />
            </Box>
          )}
          
          {activeTab === 1 && (
            <Box sx={{ py: 2 }}>
              <SupplierManager />
            </Box>
          )}
        </Box>
      )}
    </Container>
  );
};

export default AdminPage;