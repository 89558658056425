import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Fade,
  Button,
  Divider,
  Chip,
  Tooltip,
  Avatar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  LockPerson as LockIcon,
  Engineering,
  Construction,
  Factory,
  ElectricBolt,
  Warehouse,
  Close as CloseIcon,
  AccountCircle,
  Logout as LogoutIcon,
  ChevronLeft,
  ChevronRight,
  Business,
  AdminPanelSettings as AdminIcon
} from '@mui/icons-material';
import { authService } from '../services/authService';

const DRAWER_WIDTH = 240;
const MINI_DRAWER_WIDTH = 65;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  boxShadow: 'none',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  zIndex: theme.zIndex.drawer + 1,
}));

const StyledDrawer = styled(
  React.forwardRef(({ isOpen, ...props }, ref) => <Drawer ref={ref} {...props} />)
)(({ theme, isOpen }) => ({
  zIndex: theme.zIndex.drawer + 1, // Mettre le Drawer au-dessus du header
  width: isOpen ? DRAWER_WIDTH : MINI_DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  '& .MuiDrawer-paper': {
    width: isOpen ? DRAWER_WIDTH : MINI_DRAWER_WIDTH,
    background: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(10px)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    borderRight: 'none',
    boxShadow: theme.shadows[2],
  },
}));




const MenuButton = styled(
  React.forwardRef(({ isOpen, ...props }, ref) => <Button ref={ref} {...props} />)
)(({ theme, isOpen }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(1.5, isOpen ? 3 : 1),
  borderRadius: '12px',
  textTransform: 'none',
  fontWeight: 500,
  width: '100%',
  justifyContent: isOpen ? 'flex-start' : 'center',
  minWidth: 'auto',
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.04)',
  },
  '&.active': {
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
}));



const companies = [
  { name: "Europ TP", path: "/europ-tp", icon: <Construction />, color: '#00B0F6' },
  { name: "Europ ACRO", path: "/europ-acro", icon: <Engineering />, color: '#D2417F' },
  { name: "LC Métallerie", path: "/lc-metallerie", icon: <Factory />, color: '#FFDD61' },
  { name: "ETE", path: "/ete", icon: <ElectricBolt />, color: '#96CEB4' },
  { name: "LOMBART", path: "/lombart", icon: <Warehouse />, color: '#FFDD02' }
];

const Navigation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const currentUser = authService.getCurrentUser();
    if (currentUser) {
      setUser(currentUser.user);
    }
  }, []);

  const handleDrawerToggle = () => {
    if (isMobile) {
      setMobileOpen(!mobileOpen);
    } else {
      setIsOpen(!isOpen);
    }
  };

  const handleLogout = () => {
    authService.logout();
    navigate('/');
  };

  const isActive = (path) => location.pathname === path;

  const menuContent = (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        p: 2,
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        background: 'rgba(255, 255, 255, 0.5)',
      }}>
        {isOpen && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
              <Business />
            </Avatar>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, color: theme.palette.primary.main }}>
              Holding FLM
            </Typography>
          </Box>
        )}
        {!isMobile && (
          <IconButton onClick={handleDrawerToggle} size="small">
            {isOpen ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        )}
        {isMobile && (
          <IconButton onClick={handleDrawerToggle} size="small">
            <CloseIcon />
          </IconButton>
        )}
      </Box>

      {user && isOpen && (
        <Box sx={{ px: 2, py: 2 }}>
          <Chip
            icon={<AccountCircle />}
            label={user.email}
            variant="outlined"
            color="primary"
            sx={{ width: '100%' }}
          />
        </Box>
      )}

      <List sx={{ width: '100%', flex: 1, px: 0 }}>
        <ListItem disablePadding>
          <Tooltip title={!isOpen ? "Accueil" : ""} placement="right">
          <MenuButton
  component={Link}
  to="/"
  startIcon={<HomeIcon />}
  isOpen={isOpen}
  sx={{
    justifyContent: (isOpen || isMobile) ? 'flex-start' : 'center',
    textAlign: 'left',
  }}
>
  {(isOpen || isMobile) ? "Accueil" : null}
</MenuButton>

          </Tooltip>
        </ListItem>

        <Box sx={{ my: 2 }}>
          <Divider />
        </Box>

        {user && user.category === 'admin' && (
          <>
            <ListItem disablePadding>
              <Tooltip title={!isOpen ? "Administration" : ""} placement="right">
                <MenuButton
                  component={Link}
                  to="/admin"
                  startIcon={<AdminIcon />}
                  isOpen={isOpen}
                  sx={{
                    justifyContent: (isOpen || isMobile) ? 'flex-start' : 'center',
                    textAlign: 'left',
                  }}
                >
                  {(isOpen || isMobile) ? "Administration" : null}
                </MenuButton>
              </Tooltip>
            </ListItem>

            <Box sx={{ my: 2 }}>
              <Divider />
            </Box>
          </>
        )}

        {companies.map((company) => (
          <ListItem key={company.path} disablePadding>
            <Tooltip title={!isOpen ? company.name : ""} placement="right">
              <MenuButton
                component={Link}
                to={company.path}
                startIcon={isOpen && company.icon}
                isOpen={isOpen}
                className={isActive(company.path) ? 'active' : ''}
                sx={{
                  '&.active': {
                    background: `${company.color}15`,
                    color: company.color,
                  }
                }}
              >
                {!isOpen ? company.icon : company.name}
              </MenuButton>
            </Tooltip>
          </ListItem>
        ))}
      </List>

      <Box sx={{ p: 2, mt: 'auto', borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <Tooltip title={!isOpen ? "Se déconnecter" : ""} placement="right">
          <MenuButton
            onClick={handleLogout}
            startIcon={isOpen && <LogoutIcon />}
            isOpen={isOpen}
            sx={{
              color: theme.palette.error.main,
              '&:hover': {
                backgroundColor: theme.palette.error.light,
                color: theme.palette.error.dark,
              },
            }}
          >
            {!isOpen ? <LogoutIcon /> : "Se déconnecter"}
          </MenuButton>
        </Tooltip>
      </Box>
    </Box>
  );

  return (
    
<>

  <Toolbar /> {/* Spacer to avoid overlapping */}


      {isMobile ? (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              width: DRAWER_WIDTH,
              background: 'rgba(255, 255, 255, 0.9)',
              backdropFilter: 'blur(10px)',
            },
          }}
        >
          {menuContent}
        </Drawer>
      ) : (
        <StyledDrawer
          variant="permanent"
          isOpen={isOpen}
        >
          {menuContent}
        </StyledDrawer>
      )}
    </>
  );
};

export default Navigation;
