import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Tooltip,
  CircularProgress,
  Alert,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import Swal from 'sweetalert2';

const SupplierManager = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentSupplier, setCurrentSupplier] = useState({
    name: '',
    email: '',
    phone: '',
    address: ''
  });
  const [editMode, setEditMode] = useState(false);
  const [originalSupplier, setOriginalSupplier] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSuppliers();
  }, []);

  const fetchSuppliers = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`https://holdingflm.neresyscloud.com/back/api/suppliers`, {
        headers: {
          'x-api-password': process.env.REACT_APP_SUPPLIER_API_KEY,
        },
      });
      if (!response.ok) throw new Error('Erreur lors de la récupération des fournisseurs');
      const data = await response.json();
      setSuppliers(data);
    } catch (error) {
      setError('Erreur lors de la récupération des fournisseurs: ' + error.message);
      console.error('Erreur:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (supplier) => {
    setCurrentSupplier(supplier);
    setOriginalSupplier(supplier);
    setEditMode(true);
    setOpenDialog(true);
  };

  const handleDelete = async (supplier) => {
    const result = await Swal.fire({
      title: 'Êtes-vous sûr?',
      text: `Voulez-vous vraiment supprimer le fournisseur "${supplier.name}"?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Oui, supprimer',
      cancelButtonText: 'Annuler'
    });

    if (result.isConfirmed) {
      setLoading(true);
      try {
        const response = await fetch(`https://holdingflm.neresyscloud.com/back/api/suppliers/${encodeURIComponent(supplier.id)}`, {
          method: 'DELETE',
          headers: {
            'x-api-password': process.env.REACT_APP_SUPPLIER_API_KEY,
          },
        });

        if (response.ok) {
          setSuppliers(suppliers.filter(s => s.id !== supplier.id));
          Swal.fire('Supprimé!', 'Le fournisseur a été supprimé.', 'success');
        } else {
          throw new Error('Erreur lors de la suppression');
        }
      } catch (error) {
        Swal.fire('Erreur', 'Une erreur est survenue lors de la suppression.', 'error');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmit = async () => {
    if (!currentSupplier.name.trim()) {
      Swal.fire('Erreur', 'Le nom du fournisseur ne peut pas être vide.', 'error');
      return;
    }

    setLoading(true);
    try {
      if (editMode) {
        // Mise à jour du fournisseur
        const response = await fetch(`https://holdingflm.neresyscloud.com/back/api/suppliers/${encodeURIComponent(originalSupplier.id)}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-api-password': process.env.REACT_APP_SUPPLIER_API_KEY,
          },
          body: JSON.stringify(currentSupplier),
        });

        if (response.ok) {
          setSuppliers(suppliers.map(s => s.id === originalSupplier.id ? currentSupplier : s));
          Swal.fire('Succès', 'Le fournisseur a été modifié.', 'success');
        } else {
          throw new Error('Erreur lors de la modification');
        }
      } else {
        // Vérifier si le fournisseur existe déjà
        if (suppliers.some(s => s.name === currentSupplier.name)) {
          Swal.fire('Erreur', 'Ce fournisseur existe déjà.', 'error');
          return;
        }

        // Ajout d'un nouveau fournisseur
        const response = await fetch(`https://holdingflm.neresyscloud.com/back/api/suppliers`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-password': process.env.REACT_APP_SUPPLIER_API_KEY,
          },
          body: JSON.stringify(currentSupplier),
        });

        if (response.ok) {
          const newSupplier = await response.json();
          setSuppliers([...suppliers, newSupplier]);
          Swal.fire('Succès', 'Le fournisseur a été ajouté.', 'success');
        } else {
          throw new Error('Erreur lors de l\'ajout');
        }
      }

      handleClose();
    } catch (error) {
      Swal.fire('Erreur', 'Une erreur est survenue.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setCurrentSupplier({
      name: '',
      email: '',
      phone: '',
      address: ''
    });
    setEditMode(false);
    setOriginalSupplier(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentSupplier(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" component="h2">
          Liste des fournisseurs
        </Typography>
        <Box>
          <Tooltip title="Rafraîchir la liste">
            <IconButton onClick={fetchSuppliers} sx={{ mr: 1 }}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
          >
            Ajouter un fournisseur
          </Button>
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Téléphone</TableCell>
              <TableCell>Adresse</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center" sx={{ py: 3 }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : suppliers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  Aucun fournisseur trouvé
                </TableCell>
              </TableRow>
            ) : (
              suppliers.map((supplier) => (
                <TableRow key={supplier.id}>
                  <TableCell>{supplier.name}</TableCell>
                  <TableCell>{supplier.email}</TableCell>
                  <TableCell>{supplier.phone}</TableCell>
                  <TableCell>{supplier.address}</TableCell>
                  <TableCell align="right">
                    <Tooltip title="Modifier">
                      <IconButton
                        onClick={() => handleEdit(supplier)}
                        color="primary"
                        sx={{ mr: 1 }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Supprimer">
                      <IconButton
                        onClick={() => handleDelete(supplier)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog 
        open={openDialog} 
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {editMode ? 'Modifier le fournisseur' : 'Ajouter un fournisseur'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <TextField
              autoFocus
              name="name"
              label="Nom du fournisseur"
              type="text"
              fullWidth
              value={currentSupplier.name}
              onChange={handleInputChange}
              disabled={loading}
              required
            />
            <TextField
              name="email"
              label="Email"
              type="email"
              fullWidth
              value={currentSupplier.email}
              onChange={handleInputChange}
              disabled={loading}
            />
            <TextField
              name="phone"
              label="Téléphone"
              type="tel"
              fullWidth
              value={currentSupplier.phone}
              onChange={handleInputChange}
              disabled={loading}
            />
            <TextField
              name="address"
              label="Adresse"
              fullWidth
              multiline
              rows={2}
              value={currentSupplier.address}
              onChange={handleInputChange}
              disabled={loading}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            Annuler
          </Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained" 
            disabled={loading || !currentSupplier.name.trim()}
          >
            {loading ? <CircularProgress size={24} /> : editMode ? 'Modifier' : 'Ajouter'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SupplierManager;
