import { PDFDocument, rgb } from "pdf-lib";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import pica from "pica";
import { sendFileToZeendoc } from "./Uploader";
import { companyThemes } from "../config/companyThemes";

export const generatePDF = async (header, logo, lines, discount, discountType, comment, attachments, sigCanvas, totalHT, totalAfterDiscount) => {
  const collId = (() => {
    switch (header.title) {
      case "EUROP TP":
        return "coll_5";
      case "EUROP ACRO":
        return "coll_1";
      case "LC METALLERIE":
        return "coll_19";
      case "ETE":
        return "coll_11";
      case "LOMBART":
        return "coll_23";
      default:
        return "coll_1";
    }
  })();

  const theme = companyThemes[header.title];

  return new Promise((resolve, reject) => {
    const doc = new jsPDF();

    // Ajout du logo
    const img = new Image();
    img.src = logo;

    img.onload = () => {
      try {
        // Définir une taille maximale pour le logo
        const maxHeight = 25;
        const maxWidth = 50;
        
        let width = img.width;
        let height = img.height;
        
        // Calculer les nouvelles dimensions en conservant le ratio
        if (width > height) {
          if (width > maxWidth) {
            height = height * (maxWidth / width);
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = width * (maxHeight / height);
            height = maxHeight;
          }
        }

        doc.addImage(img, "PNG", 10, 10, width, height);

        // Ajouter le titre "BON DE COMMANDE"
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("BON DE COMMANDE", 185, 20, { align: "right" });
        doc.rect(130, 10, 60, 18);

        // Ajouter la date
        const date = new Date();
        const formattedDate = `${String(date.getDate()).padStart(2, "0")}/${String(date.getMonth() + 1).padStart(2, "0")}/${date.getFullYear()}`;

        // Informations d'en-tête
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        doc.text(`Titre: ${header.title}`, 20, 50);
        doc.text(`Date: ${formattedDate}`, 20, 60);
        if (header.orderNumber) {
          doc.text(`N° de commande: ${header.orderNumber}`, 20, 67);
        }
        doc.text(`Référence de commande fournisseur: ${header.supplierOrderRef}`, 20, 74);
        doc.text(`Signataire: ${header.signatory}`, 20, 81);

        // Détails du fournisseur
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Fournisseur :`, 130, 50);
        doc.text(`${header.supplier}`, 130, 55);

        // Tableau des produits
        const startY = 90;
        autoTable(doc, {
          head: [["Désignation", "Quantité", "Prix unitaire HT", "Total HT"]],
          body: lines.map((line) => [
            line.designation,
            line.quantity,
            `${parseFloat(line.unitPrice).toFixed(2)} €`,
            `${parseFloat(line.totalPrice).toFixed(2)} €`,
          ]),
          startY: startY,
          theme: "striped",
          headStyles: {
            fillColor: theme.tableHeader,
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
          styles: {
            cellPadding: 5,
          },
          columnStyles: {
            0: { cellWidth: 'auto' },
            1: { cellWidth: 30, halign: "center" },
            2: { cellWidth: 40, halign: "right" },
            3: { cellWidth: 40, halign: "right" },
          },
        });

        let finalY = doc.lastAutoTable.finalY;

        // Commentaire
        if (comment) {
          doc.setFontSize(10);
          doc.setTextColor(0, 0, 0);
          doc.text("Commentaire:", 20, finalY + 10);
          doc.text(comment, 20, finalY + 20);
          finalY += 30;
        }

        // Afficher le total HT et la remise
        let y = doc.lastAutoTable.finalY + 20;
        doc.setFontSize(10);
        
        // Total HT avant remise
        doc.setFont(undefined, 'normal');
        doc.setTextColor(0, 0, 0);
        doc.text("Total HT avant remise:", 120, y);
        doc.text(`${totalHT.toFixed(2)} €`, 190, y, { align: "right" });
        
        // Remise
        y += 8;
        if (discountType === 'percentage') {
          const discountAmount = (totalHT * discount) / 100;
          doc.text(`Remise (${parseFloat(discount).toFixed(2)}%):`, 120, y);
          doc.text(`${discountAmount.toFixed(2)} €`, 190, y, { align: "right" });
        } else {
          doc.text(`Remise:`, 120, y);
          doc.text(`${parseFloat(discount).toFixed(2)} €`, 190, y, { align: "right" });
        }
        
        // Total HT final
        y += 8;
        doc.setFont(undefined, 'bold');
        doc.setTextColor(...theme.tableHeader);
        doc.text("Total HT final:", 120, y);
        doc.text(`${totalAfterDiscount.toFixed(2)} €`, 190, y, { align: "right" });

        // Générer la signature et les pièces jointes
        generateSignatureAndAttachments(doc, attachments, sigCanvas, header).then(async () => {
          // Générer le nom du fichier avec la date
          const date = new Date();
          const formattedDate = `${String(date.getDate()).padStart(2, "0")}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}`;
          const fileName = `${header.title}_${formattedDate}.pdf`;

          try {
            // Sauvegarder le PDF
            const pdfOutput = doc.output('blob');
            
            // Envoyer à Zeendoc
            await sendFileToZeendoc(pdfOutput, fileName, collId);

            // Télécharger le PDF
            const pdfUrl = URL.createObjectURL(pdfOutput);
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(pdfUrl);

            resolve(doc);
          } catch (error) {
            reject(error);
          }
        }).catch(reject);

      } catch (error) {
        reject(error);
      }
    };

    img.onerror = () => {
      reject(new Error("Erreur lors du chargement du logo"));
    };
  });
};

// Fonction de génération de la signature et des pièces jointes
const generateSignatureAndAttachments = async (doc, attachments, sigCanvas, header) => {
  const pdfBytes = await doc.output("arraybuffer");
  let finalDoc = await PDFDocument.load(pdfBytes);

  const signatureImage = sigCanvas.current.getTrimmedCanvas({ willReadFrequently: true }).toDataURL("image/png");
  const signatureImageBytes = await fetch(signatureImage).then((res) => res.arrayBuffer());
  const signatureImageEmbed = await finalDoc.embedPng(signatureImageBytes);

  const pages = finalDoc.getPages();
  const lastPage = pages[pages.length - 1];
  const signatureY = lastPage.getHeight() - 750;
  const signatureText = `Signature du demandeur : ${header.signatory}`;

  lastPage.drawText(signatureText, {
    x: 40,
    y: signatureY + 90,
    size: 10,
  });

  lastPage.drawImage(signatureImageEmbed, {
    x: 40,
    y: signatureY,
    width: 120,
    height: 70,
  });

  lastPage.drawRectangle({
    x: 35,
    y: signatureY - 20,
    width: 170,
    height: 90,
    borderColor: rgb(0, 0, 0),
    borderWidth: 1,
  });

  for (let attachment of attachments) {
    if (attachment.file) {
      const { file } = attachment;
      const fileType = file.type;

      if (fileType === "application/pdf") {
        const attachmentBytes = await file.arrayBuffer();
        const attachmentDoc = await PDFDocument.load(attachmentBytes);
        const copiedPages = await finalDoc.copyPages(attachmentDoc, attachmentDoc.getPageIndices());
        copiedPages.forEach((page) => finalDoc.addPage(page));
      } else if (fileType === "image/jpeg") {
        const imgBlob = await resizeImage(file, 595.28 - 20, 841.89 - 20);
        const imgBytes = await imgBlob.arrayBuffer();
        const img = await finalDoc.embedJpg(imgBytes);
        const page = finalDoc.addPage([595.28, 841.89]);
        page.drawImage(img, {
          x: (595.28 - img.width) / 2,
          y: (841.89 - img.height) / 2,
          width: img.width,
          height: img.height,
        });
      }
    }
  }

  return await finalDoc.save();
};

// Fonction pour redimensionner les images
const resizeImage = (file, maxWidth, maxHeight) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
      const width = img.width * ratio;
      const height = img.height * ratio;

      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      pica()
        .resize(img, canvas, { quality: 3 })
        .then(() => {
          canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            "image/jpeg",
            0.8
          );
        })
        .catch(reject);
    };

    img.onerror = reject;
  });
};
