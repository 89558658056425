// HomePage.js

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { 
  Container, 
  Box, 
  Button, 
  Typography, 
  Grid, 
  Alert, 
  Modal, 
  TextField,
  Paper,
  useTheme,
  useMediaQuery,
  IconButton,
  Fade,
  Stack,
  CircularProgress,
  Chip
} from "@mui/material";
import { 
  Visibility,
  VisibilityOff,
  AccountCircle,
  Email as EmailIcon,
  ArrowForward,
  Person,
  Engineering,
  Construction,
  Factory,
  ElectricBolt,
  Warehouse
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { authService } from '../services/authService';

// Styled components
const GradientButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
  borderRadius: '12px',
  border: 0,
  color: 'white',
  padding: '16px 32px',
  boxShadow: '0 3px 5px 2px rgba(33, 150, 243, .3)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-3px)',
    boxShadow: '0 6px 12px 4px rgba(33, 150, 243, .3)',
  }
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  overflow: 'hidden',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
}));

const companies = [
  { 
    name: "EUROP TP",
    path: "/europ-tp",
    icon: <Construction sx={{ fontSize: 32 }}/>,
    gradient: 'linear-gradient(45deg, #1976d2 30%, #64b5f6 90%)'
  },
  { 
    name: "EUROP ACRO",
    path: "/europ-acro",
    icon: <Engineering sx={{ fontSize: 32 }}/>,
    gradient: 'linear-gradient(45deg, #0288d1 30%, #4fc3f7 90%)'
  },
  { 
    name: "LC METALLERIE",
    path: "/lc-metallerie",
    icon: <Factory sx={{ fontSize: 32 }}/>,
    gradient: 'linear-gradient(45deg, #0277bd 30%, #29b6f6 90%)'
  },
  { 
    name: "ETE",
    path: "/ete",
    icon: <ElectricBolt sx={{ fontSize: 32 }}/>,
    gradient: 'linear-gradient(45deg, #01579b 30%, #03a9f4 90%)'
  },
  { 
    name: "LOMBART",
    path: "/lombart",
    icon: <Warehouse sx={{ fontSize: 32 }}/>,
    gradient: 'linear-gradient(45deg, #1976d2 30%, #64b5f6 90%)'
  }
];

const HomePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("mail") || "";

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState(null);

  const handleLogin = async () => {
    if (!inputEmail || !inputPassword) {
      setErrorMessage("Veuillez remplir tous les champs");
      return;
    }

    setLoading(true);
    try {
      const result = await authService.login(inputEmail, inputPassword);
      if (result.token) {
        navigate(`?mail=${encodeURIComponent(inputEmail)}`);
        setIsModalOpen(false);
        setErrorMessage("");
        setIsAuthorized(true);
        setUser(result.user);
      } else {
        setErrorMessage("Erreur lors de la connexion");
      }
    } catch (error) {
      setErrorMessage(error.message || "Erreur lors de la connexion");
      setIsAuthorized(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      const currentUser = authService.getCurrentUser();
      if (currentUser && currentUser.token) {
        setIsAuthorized(true);
        setUser(currentUser.user);
      } else {
        setIsAuthorized(false);
      }
      setLoading(false);
    };

    checkAuth();
  }, []);

  const generateLink = (path) => `${path}${location.search}`;
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  if (loading) {
    return (
      <Container>
        <Box sx={{ 
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2
        }}>
          <CircularProgress />
          <Typography variant="body1" color="textSecondary">
            Vérification de l'autorisation...
          </Typography>
        </Box>
      </Container>
    );
  }

  if (!isAuthorized) {
    return (
      <Container maxWidth="sm">
        <Box sx={{ 
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: theme.spacing(3)
        }}>
          <Fade in={true} timeout={800}>
            <StyledPaper elevation={0} sx={{ width: '100%', p: 4 }}>
              <Box sx={{ textAlign: 'center', mb: 4 }}>
                <Typography 
                  variant="h4" 
                  component="h1" 
                  sx={{ 
                    fontWeight: 700,
                    background: 'linear-gradient(45deg, #1976d2, #64b5f6)',
                    backgroundClip: 'text',
                    textFillColor: 'transparent',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    mb: 2
                  }}
                >
                  Holding FLM
                </Typography>
                {errorMessage && (
                  <Alert 
                    severity="error" 
                    sx={{ 
                      mb: 3,
                      borderRadius: '8px'
                    }}
                  >
                    {errorMessage}
                  </Alert>
                )}
              </Box>
              
              <GradientButton
                fullWidth
                onClick={handleOpenModal}
                startIcon={<EmailIcon />}
                endIcon={<ArrowForward />}
              >
                Connexion avec Email
              </GradientButton>
            </StyledPaper>
          </Fade>
        </Box>

        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Fade in={isModalOpen}>
            <StyledPaper sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '90%', sm: 400 },
              p: 4
            }}>
              <Typography variant="h6" sx={{ mb: 3, textAlign: 'center' }}>
                Connexion
              </Typography>

              {errorMessage && (
                <Alert 
                  severity="error" 
                  sx={{ mb: 2 }}
                  onClose={() => setErrorMessage("")}
                >
                  {errorMessage}
                </Alert>
              )}

              <Box component="form" onSubmit={(e) => { e.preventDefault(); handleLogin(); }}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  value={inputEmail}
                  onChange={(e) => setInputEmail(e.target.value)}
                  type="email"
                  required
                  sx={{ mb: 2 }}
                  InputProps={{
                    startAdornment: (
                      <EmailIcon color="action" sx={{ mr: 1 }} />
                    ),
                  }}
                />

                <TextField
                  fullWidth
                  label="Mot de passe"
                  variant="outlined"
                  value={inputPassword}
                  onChange={(e) => setInputPassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  required
                  sx={{ mb: 3 }}
                  InputProps={{
                    startAdornment: (
                      <Person color="action" sx={{ mr: 1 }} />
                    ),
                    endAdornment: (
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />

                <GradientButton
                  fullWidth
                  onClick={handleLogin}
                  disabled={loading}
                  startIcon={loading ? <CircularProgress size={20} /> : null}
                >
                  {loading ? "Connexion en cours..." : "Se connecter"}
                </GradientButton>
              </Box>
            </StyledPaper>
          </Fade>
        </Modal>
      </Container>
    );
  }

  return (
    <Box sx={{ 
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(3),
      width: '100%',
      overflow: 'auto'
    }}>
      <Container maxWidth="lg">
        <Fade in={true} timeout={800}>
          <StyledPaper sx={{ p: { xs: 3, sm: 4, md: 5 } }}>
            <Typography 
              variant={isMobile ? "h5" : "h4"} 
              component="h1" 
              align="center"
              sx={{ 
                fontWeight: 700,
                background: 'linear-gradient(45deg, #1976d2, #64b5f6)',
                backgroundClip: 'text',
                textFillColor: 'transparent',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                mb: 1
              }}
            >
              Portail de commande
            </Typography>
            
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              mb: 4,
              color: 'text.secondary'
            }}>
              <Chip
                icon={<AccountCircle />}
                label={user.email}
                variant="outlined"
                color="primary"
                sx={{ mb: 2 }}
              />
            </Box>

            <Box 
              sx={{ 
                maxWidth: { xs: '100%', sm: '400px' }, 
                mx: 'auto',
                width: '100%'
              }}
            >
              <Stack spacing={2} width="100%">
                {companies.map((company) => (
                  <Fade in={true} timeout={1000} key={company.name}>
                    <Button
                      component={Link}
                      to={generateLink(company.path)}
                      fullWidth
                      sx={{
                        background: company.gradient,
                        color: 'white',
                        py: 1.5,
                        px: 3,
                        borderRadius: '12px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 2,
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                          transform: 'translateY(-2px)',
                          boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                        }
                      }}
                    >
                      {company.icon}
                      <Typography 
                        variant="body1" 
                        component="span"
                        sx={{ 
                          fontWeight: 500,
                          textTransform: 'none'
                        }}
                      >
                        {company.name}
                      </Typography>
                    </Button>
                  </Fade>
                ))}
              </Stack>
            </Box>
          </StyledPaper>
        </Fade>
      </Container>
    </Box>
  );
};

export default HomePage;
