import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import EuropTPForm from './pages/EuropTPForm';
import EuropAcroForm from './pages/EuropAcroForm';
import LCMetallerieForm from './pages/LCMetallerieForm';
import ETEForm from './pages/ETEForm';
import LombartForm from './pages/LombartForm';
import AdminPage from './pages/AdminPage';
import Navigation from './components/Navigation';
import { AuthProvider, useAuth } from './context/AuthContext';
import './App.css';

// Protected Route component
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/login" />;
};

function AppRoutes() {
  const { isAuthenticated } = useAuth();

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      {isAuthenticated && <Navigation />}
      <Box component="main" sx={{ flexGrow: 1, position: 'relative' }}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          } />
          <Route path="/europ-tp" element={
            <ProtectedRoute>
              <EuropTPForm />
            </ProtectedRoute>
          } />
          <Route path="/europ-acro" element={
            <ProtectedRoute>
              <EuropAcroForm />
            </ProtectedRoute>
          } />
          <Route path="/lc-metallerie" element={
            <ProtectedRoute>
              <LCMetallerieForm />
            </ProtectedRoute>
          } />
          <Route path="/ete" element={
            <ProtectedRoute>
              <ETEForm />
            </ProtectedRoute>
          } />
          <Route path="/lombart" element={
            <ProtectedRoute>
              <LombartForm />
            </ProtectedRoute>
          } />
          <Route path="/admin" element={
            <ProtectedRoute>
              <AdminPage />
            </ProtectedRoute>
          } />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Box>
    </Box>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppRoutes />
      </Router>
    </AuthProvider>
  );
}

export default App;