import axios from 'axios';

const API_URL = 'https://holdingflm.neresyscloud.com/back/api';

const headers = {
  'Content-Type': 'application/json',
  'x-api-password': process.env.REACT_APP_SUPPLIER_API_KEY,
};

export const userService = {
  getAllUsers: async () => {
    try {
      const response = await axios.get(`${API_URL}/users`, { headers });
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  },

  getUserById: async (id) => {
    try {
      const response = await axios.get(`${API_URL}/users/${id}`, { headers });
      return response.data;
    } catch (error) {
      console.error('Error fetching user:', error);
      throw error;
    }
  },

  login: async (email, password) => {
    try {
      const response = await axios.post(`${API_URL}/login`, { email, password }, { headers });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error('Email ou mot de passe incorrect');
      }
      console.error('Error during login:', error);
      throw error;
    }
  },

  checkUserAuthorization: async (email, password) => {
    try {
      const loginResult = await userService.login(email, password);
      return {
        authorized: true,
        user: loginResult.user,
        message: "Utilisateur autorisé"
      };
    } catch (error) {
      console.error('Error checking authorization:', error);
      return {
        authorized: false,
        user: null,
        message: error.message || "Accès non autorisé"
      };
    }
  },

  createUser: async (userData) => {
    try {
      const response = await axios.post(`${API_URL}/users`, userData, { headers });
      return response.data;
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;
    }
  },

  updateUser: async (id, userData) => {
    try {
      const response = await axios.put(`${API_URL}/users/${id}`, userData, { headers });
      return response.data;
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  },

  deleteUser: async (id) => {
    try {
      const response = await axios.delete(`${API_URL}/users/${id}`, { headers });
      return response.data;
    } catch (error) {
      console.error('Error deleting user:', error);
      throw error;
    }
  }
};
