import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TextField, Button, Typography, Container, Box, Grid, InputAdornment, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Alert, Select, MenuItem, FormControl, InputLabel, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import OrderLines from "./OrderLines";
import Attachments from "./Attachments";
import Signature from "./Signature";
import { generatePDF } from "./PDFGenerator";
import Swal from "sweetalert2";
import "./OrderForm.css";
import { companyThemes } from "../config/companyThemes";
import { authService } from "../services/authService";

const SectionTitle = ({ title, style }) => (
  <Box display="flex" alignItems="center" mt={4} mb={2} style={style}>
    <Divider style={{ flexGrow: 1 }} />
    <Typography variant="h6" style={{ margin: "0 20px", whiteSpace: "nowrap" }}>
      {title}
    </Typography>
    <Divider style={{ flexGrow: 1 }} />
  </Box>
);

const OrderForm = ({ logo, title }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // Vérifier l'authentification
    const currentUser = authService.getCurrentUser();
    if (!currentUser || !currentUser.token) {
      setErrorMessage("Veuillez vous connecter");
      navigate('/');
    }
  }, [navigate]);

  const [header, setHeader] = useState({
    title: title,
    supplier: "",
    projectNumber: "",
    supplierOrderRef: "",
    signatory: authService.getCurrentUser()?.user?.email || "", // Utiliser l'email de l'utilisateur connecté
    orderNumber: ""
  });

  const [suppliers, setSuppliers] = useState([]);

  useEffect(() => {
    // Fetch suppliers from backend with password authentication
    fetch('https://holdingflm.neresyscloud.com/back/api/suppliers', {
      headers: {
        'x-api-password': process.env.REACT_APP_SUPPLIER_API_KEY, // Remplacez par le mot de passe exact
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => setSuppliers(data))
      .catch(error => console.error('Error fetching suppliers:', error));
  }, []);

  const [lines, setLines] = useState([{ designation: "", quantity: "", unitPrice: "", totalPrice: "0.00" }]);

  const [discount, setDiscount] = useState(0);
  const [discountType, setDiscountType] = useState('percentage'); // 'percentage' ou 'fixed'
  const [comment, setComment] = useState("");
  const [attachments, setAttachments] = useState([{ file: null, type: "" }]);
  const [fileErrors, setFileErrors] = useState([]);
  const [totalHT, setTotalHT] = useState(0);
  const [totalAfterDiscount, setTotalAfterDiscount] = useState(0);
  const sigCanvas = useRef(null);
  const [signatureError, setSignatureError] = useState(false);

  const [openSupplierModal, setOpenSupplierModal] = useState(false);
  const [newSupplier, setNewSupplier] = useState({
    name: '',
    email: '',
    phone: '',
    address: ''
  });

  const theme = companyThemes[title];

  const handleHeaderChange = (e) => {
    const { name, value } = e.target;
    setHeader({
      ...header,
      [name]: value,
    });
  };

  const handleLineChange = (index, e) => {
    const { name, value } = e.target;
    const newLines = [...lines];
    newLines[index][name] = value;
    if (name === "quantity" || name === "unitPrice") {
      newLines[index].totalPrice = (newLines[index].quantity * newLines[index].unitPrice).toFixed(2);
    }
    setLines(newLines);
  };

  const handleAddLine = () => {
    setLines([...lines, { designation: "", quantity: "", unitPrice: "", totalPrice: "0.00" }]);
  };

  const handleRemoveLine = (index) => {
    const newLines = lines.filter((_, i) => i !== index);
    setLines(newLines);
  };

  const handleDiscountChange = (e) => {
    const value = e.target.value;
    if (discountType === 'percentage' && value > 100) {
      setDiscount(100);
    } else {
      setDiscount(value);
    }
  };

  const handleDiscountTypeChange = (e) => {
    const newType = e.target.value;
    setDiscountType(newType);
    setDiscount(0); // Réinitialiser la remise lors du changement de type
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    const totalHTValue = lines.reduce((sum, line) => sum + parseFloat(line.totalPrice || 0), 0);
    let discountValue = 0;
    
    if (discountType === 'percentage') {
      discountValue = (totalHTValue * discount) / 100;
    } else {
      discountValue = parseFloat(discount) || 0;
    }

    const totalAfterDiscountValue = totalHTValue - discountValue;

    setTotalHT(totalHTValue);
    setTotalAfterDiscount(totalAfterDiscountValue);
  }, [lines, discount, discountType]);

  const handleOpenSupplierModal = () => {
    setOpenSupplierModal(true);
  };

  const handleCloseSupplierModal = () => {
    setOpenSupplierModal(false);
    setNewSupplier({ name: '', email: '', phone: '', address: '' });
  };

  const handleNewSupplierChange = (e) => {
    const { name, value } = e.target;
    setNewSupplier(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddSupplier = async () => {
    try {
      const response = await fetch('https://holdingflm.neresyscloud.com/back/api/suppliers', {
        method: 'POST',
        headers: {
          'x-api-password': process.env.REACT_APP_SUPPLIER_API_KEY,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newSupplier)
      });

      if (response.ok) {
        // Refresh suppliers list
        fetch('https://holdingflm.neresyscloud.com/back/api/suppliers', {
          headers: {
            'x-api-password': process.env.REACT_APP_SUPPLIER_API_KEY,
            'Content-Type': 'application/json',
          },
        })
          .then(response => response.json())
          .then(data => setSuppliers(data))
          .catch(error => console.error('Error fetching suppliers:', error));

        handleCloseSupplierModal();
        Swal.fire({
          icon: 'success',
          title: 'Fournisseur ajouté avec succès',
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        throw new Error('Erreur lors de l\'ajout du fournisseur');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Une erreur est survenue lors de l\'ajout du fournisseur'
      });
    }
  };

  const generateOrderNumber = async (projectNumber) => {
    try {
      // Créer un nouveau numéro
      const response = await fetch('https://holdingflm.neresyscloud.com/back/api/orders/new', {
        method: 'PUT',
        headers: {
          'x-api-password': process.env.REACT_APP_SUPPLIER_API_KEY,
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la création du numéro de commande');
      }

      const data = await response.json();
      const year = new Date().getFullYear().toString().slice(-2);
      const orderNumber = `${projectNumber}-${year}-${data.order_number.toString().padStart(5, '0')}`;
      
      setHeader(prev => ({
        ...prev,
        orderNumber
      }));

      return orderNumber;
    } catch (error) {
      console.error('Erreur:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (sigCanvas.current.isEmpty()) {
      setSignatureError(true);
      return;
    }
    setSignatureError(false);

    try {
      // Vérifier que le numéro de chantier est renseigné
      if (!header.projectNumber) {
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: "Le numéro de chantier est obligatoire",
        });
        return;
      }

      // Vérifier que le montant ne dépasse pas la limite de l'utilisateur
      const currentUser = authService.getCurrentUser()?.user;
      if (currentUser && totalAfterDiscount > currentUser.limit) {
        Swal.fire({
          icon: "error",
          title: "Limite dépassée",
          text: `Le montant total (${totalAfterDiscount.toFixed(2)}€) dépasse votre limite autorisée (${currentUser.limit}€)`,
        });
        return;
      }

      // Générer le numéro de commande
      const orderNumber = await generateOrderNumber(header.projectNumber);
      
      // Générer le PDF avec le numéro de commande
      await generatePDF({
        ...header,
        orderNumber
      }, logo, lines, discount, discountType, comment, attachments, sigCanvas, totalHT, totalAfterDiscount);

      Swal.fire({
        icon: "success",
        title: "Formulaire envoyé avec succès",
        text: `Votre commande n°${orderNumber} a été créée et enregistrée avec succès !`,
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/");
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erreur d'envoi",
        text: "Une erreur est survenue lors de la création de la commande. Veuillez réessayer.",
        confirmButtonText: "Réessayer",
      });
    }
  };

  if (errorMessage) {
    return (
      <Container>
        <Box mt={5} mb={5} textAlign="center">
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box mt={5} mb={5} style={{ marginTop: '80px' }}>
        <img src={logo} alt={`${title} Logo`} className="logo" />
      </Box>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        Créer une Commande - {title}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField 
              label="Titre de la Demande" 
              name="title" 
              value={header.title} 
              onChange={handleHeaderChange} 
              fullWidth 
              InputProps={{ 
                style: { textAlign: "center" },
                startAdornment: header.orderNumber ? (
                  <InputAdornment position="start">
                    N° {header.orderNumber}
                  </InputAdornment>
                ) : null
              }} 
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" gap={1}>
              <FormControl fullWidth>
                <InputLabel id="supplier-label">Fournisseur</InputLabel>
                <Select
                  labelId="supplier-label"
                  id="supplier"
                  name="supplier"
                  value={header.supplier}
                  onChange={handleHeaderChange}
                  label="Fournisseur"
                >
                  {Array.isArray(suppliers) &&
                    suppliers.map((supplier) => (
                      <MenuItem key={supplier.id} value={supplier.name}>
                        {supplier.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleOpenSupplierModal}
                style={{ minWidth: 'auto', width: '48px' }}
              >
                +
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Numéro de Chantier" name="projectNumber" value={header.projectNumber} onChange={handleHeaderChange} fullWidth required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Référence de Commande Fournisseur" name="supplierOrderRef" value={header.supplierOrderRef} onChange={handleHeaderChange} fullWidth required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Signataire"
              name="signatory"
              value={header.signatory}
              onChange={handleHeaderChange}
              fullWidth
              required
              InputProps={{
                readOnly: true,
                style: { backgroundColor: "#f0f0f0" }, // Griser le champ pour indiquer qu'il est non modifiable
              }}
            />
          </Grid>
        </Grid>

        <SectionTitle 
          title="Lignes de Produit" 
          style={{ 
            backgroundColor: theme.primary,
            color: 'white',
            padding: '8px 16px',
            borderRadius: '4px',
            marginTop: '20px',
            marginBottom: '10px'
          }} 
        />
        <OrderLines lines={lines} handleLineChange={handleLineChange} handleAddLine={handleAddLine} handleRemoveLine={handleRemoveLine} />

        <Box mt={2} style={{ display: "flex", justifyContent: "flex-end", gap: "16px" }}>
          <FormControl style={{ width: "150px" }}>
            <InputLabel id="discount-type-label">Type de remise</InputLabel>
            <Select
              labelId="discount-type-label"
              id="discount-type"
              value={discountType}
              onChange={handleDiscountTypeChange}
              label="Type de remise"
            >
              <MenuItem value="percentage">Pourcentage (%)</MenuItem>
              <MenuItem value="fixed">Montant fixe (€)</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label={discountType === 'percentage' ? "Remise en %" : "Remise en €"}
            type="number"
            value={discount}
            onChange={handleDiscountChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {discountType === 'percentage' ? '%' : '€'}
                </InputAdornment>
              ),
            }}
            style={{ width: "150px" }}
          />
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={3} style={{ marginLeft: "auto" }}>
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ backgroundColor: theme.primary, color: "white" }}></TableCell>
                    <TableCell style={{ backgroundColor: theme.primary, color: "white", textAlign: "center" }}>Montant (€)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ backgroundColor: theme.primary, color: "white" }}>Montant HT Avant Remise</TableCell>
                    <TableCell style={{ textAlign: "right" }}>{totalHT.toFixed(2)} €</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ backgroundColor: theme.primary, color: "white" }}>
                      {discountType === 'percentage' ? 'Remise (%)' : 'Remise (€)'}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {discountType === 'percentage' ? 
                        `${parseFloat(discount || 0).toFixed(2)} % (${((totalHT * discount) / 100).toFixed(2)} €)` : 
                        `${parseFloat(discount || 0).toFixed(2)} €`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ backgroundColor: theme.primary, color: "white" }}>Montant HT Final</TableCell>
                    <TableCell style={{ textAlign: "right" }}>{totalAfterDiscount.toFixed(2)} €</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Box mt={2}>
          <TextField label="Commentaire" name="comment" value={comment} onChange={handleCommentChange} fullWidth multiline rows={4} />
        </Box>

        <SectionTitle title="Pièces Jointes" />
        <Attachments attachments={attachments} setAttachments={setAttachments} fileErrors={fileErrors} setFileErrors={setFileErrors} />

        <SectionTitle title="Signature" />
        <Signature sigCanvas={sigCanvas} signatureError={signatureError} clearSignature={() => sigCanvas.current.clear()} setSignatureError={setSignatureError} />

        <Box mt={2} style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              marginTop: "20px",
              backgroundColor: theme.primary,
              color: "white",
              "&:hover": {
                backgroundColor: theme.secondary,
              },
            }}
          >
            Soumettre
          </Button>
        </Box>
        <Box mb={4}></Box>
      </form>

      {/* Modal d'ajout de fournisseur */}
      <Dialog open={openSupplierModal} onClose={handleCloseSupplierModal}>
        <DialogTitle>Ajouter un nouveau fournisseur</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nom"
                  name="name"
                  value={newSupplier.name}
                  onChange={handleNewSupplierChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={newSupplier.email}
                  onChange={handleNewSupplierChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Téléphone"
                  name="phone"
                  value={newSupplier.phone}
                  onChange={handleNewSupplierChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Adresse"
                  name="address"
                  multiline
                  rows={3}
                  value={newSupplier.address}
                  onChange={handleNewSupplierChange}
                  required
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSupplierModal}>Annuler</Button>
          <Button onClick={handleAddSupplier} variant="contained" color="primary">
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default OrderForm;
