// Fonction utilitaire pour convertir les couleurs hex en RGB
const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ] : null;
};

export const companyThemes = {
  "EUROP TP": {
    primary: "#00B0F6",  // Rouge
    secondary: "#000000",
    tableHeader: hexToRgb("#00B0F6"), // Format RGB pour jsPDF
    name: "EUROP TP"
  },
  "EUROP ACRO": {
    primary: "#D2417F", // Turquoise
    secondary: "#000000",
    tableHeader: hexToRgb("#D2417F"),
    name: "EUROP ACRO"
  },
  "LC METALLERIE": {
    primary: "#FFDD61", // Bleu
    secondary: "#000000",
    tableHeader: hexToRgb("#FFDD61"),
    name: "LC METALLERIE"
  },
  "ETE": {
    primary: "#96CEB4", // Vert
    secondary: "#000000",
    tableHeader: hexToRgb("#96CEB4"),
    name: "LOMBART"
  },
  "LOMBART": {
    primary: "#FFDD02", // Vert
    secondary: "#000000",
    tableHeader: hexToRgb("#FFDD02"),
    name: "LOMBART"
  }
};
